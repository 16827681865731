@import "../../../constants/Colors.scss";

.FormInput {
	width: 100%;
	box-sizing: border-box;
	padding:0px;
	margin:0;
}

.InputBox {
    outline: none;
	box-sizing: border-box;
    transition : all 0.30s ease-in-out;
	width: 100%;
	background: $white;
	border: 1px solid $contentFontTintColor;
	border-radius: 5px;
	padding: 5px 18px 5px 10px;
	color: $contentFontColor;
	font-size: 0.95rem;
}

.InputBox:focus {
    box-shadow: 0 0 5px $primaryButtonBGColor;
	border: 1px solid $primaryButtonBGColor;
}

.ClearIconText {
	position: absolute; 
	padding-right: 5px;
	color: $contentFontTintColor;
	font-size:1em;
}

.ClearTextLabel {
	position: absolute; 
	padding-right: 10px;
	padding-bottom: 10px;
	color: $contentFontTintColor;
	font-size:0.7em;
}

.remainTextLabel {
	position: absolute; 
	padding-right: 4em;
	padding-bottom: 10px;
	color: $contentFontTintColor;
	font-size:0.7em;
}

.Label {
	display: block;
	font-weight: bold;
	font-size: 0.7rem;
	margin-bottom: 1%;
	color:$contentFontTintColor; 
}
.Error {
	font-size: 0.7em;
	color: $redColor;
}

.ValidItem {
	border: solid 1px $green;
	background: $greenTint;  
}

.ErrorItem {
	border: solid 1px $redColor;
	background: $redColorTint;   
}