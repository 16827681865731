@import "../../../constants/Colors.scss";

.SideMenu {
    min-width: 250px;
    flex:1;
    margin-right:10px;
}

.SideMenu ul {
    list-style: none;
    background-color: $white;
    color: $contentFontColor;
    font-size: 0.85rem;
    cursor: pointer;
    width: 100%;
    border: none;
    margin-bottom:15px;
}

.SecTile {
    background-color: $primaryButtonBGColorTint;
    border-radius: 5px 5px 0 0;
    color: $contentFontColor;
    font-size: 0.90rem;
    font-weight: bold;
    cursor: pointer;
    padding: 10px;
    width: 100%;
    text-align: left;
    border: none;
    transition: 0.4s;
    border: 1px solid $contentFontTintColor;
    border-bottom: none;
}

.Active:after, .SubActive:after {
    content: "\f0d8"; /* fontawesome caret-up sign */
}

.SubAccordion:after {
    margin-left: 0px;
}

ul.Panel {
    border-radius:0;
    margin-bottom:0;
    max-height: 0;
    overflow:hidden;
    transition: max-height 0.2s ease-out;
}

ul.Panel > li  {
    padding: 8px 20px;
    font-size: 0.8rem;
    border: 1px solid $contentFontTintColor;
    border-bottom: none;  
}

div.SubPanel {
    max-height: 0;
    overflow:hidden;
    transition: max-height 0.2s ease-out; 
}

div.SubPanel > li  {
    padding: 8px 30px;
    font-size: 0.75rem;
    border: 1px solid $contentFontTintColor;
    border-bottom: none; 
}

.SideMenu li:hover, .MenuActive  {
    color: $primaryButtonBGColor;
    font-weight: bold;
    background-color: $contentBGColor;
}

.Active, .SubActive {
    color: $primaryButtonBGColor;
    font-weight: bold;
}

.SideMenu > ul > li {
    padding: 8px 10px;
    text-align: left;
    border: 1px solid $contentFontTintColor;
    border-bottom: none;
}

.SideMenu > ul > li:last-child {
    border-bottom: 1px solid $contentFontTintColor;
    border-radius: 0 0 5px 5px;
}

.SideMenu > ul > li.First {
    border-radius: 5px 5px 0 0;
}

@media (min-width: 600px) {
    .SideMenu {
        margin-left: 10px;
    }
}
