@import "../../../constants/Colors.scss";

.CaseBox {
    width:100%;
    border: 1px solid $primaryButtonBGColor;
    border-radius: 5px;
    padding: 5px;
    display:flex; 
    flex-direction:row; 
    justify-content:flex-start;
    align-items:center;
    background: $primaryButtonBGColorTint;
}

.CaseBox:hover {
    background-color: $primaryButtonBGColorTint;
}

.Active {
    border-radius: 5px 5px 0 0;
    background-color: $primaryButtonBGColorTint;
}

.CaseContent {
    padding:  0 10px;
    overflow: hidden;
    background-color: $contentBGColor;
    border: 1px solid $primaryButtonBGColor;
    border-radius: 0 0 5px 5px;
    display: none;
    transition: 0.4s;
}

.CaseBox:after {
    content: '\02795'; /* Unicode character for "plus" sign (+) */
    font-size: 13px;
    color: white;
    float: right;
    margin-left: 5px;
}
  
.Active:after {
    content: "\2796"; /* Unicode character for "minus" sign (-) */
}
