@import "./constants/Colors.scss";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.App {
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 100%;
}

html, body {
  max-width: 100%;
  overflow-x: hidden;
}

.Show {
  display: block;
}
.Hide {
  display: none;
}

.Fade {
  -webkit-animation-name: Fade;
  -webkit-animation-duration: 1.5s;
  animation-name: Fade;
  animation-duration: 1.5s;
}

@-webkit-keyframes Fade {
  from {opacity: .4}
  to {opacity: 1}
}

@keyframes Fade {
  from {opacity: .4}
  to {opacity: 1}
}

h1 {
  font-size:1.3rem; 
}

h2 {
  font-size:1.2rem;  
}

h3 {
  font-size: 1.1rem;  
}

h4 {
  font-size:1rem;  
}

h5 {
  font-size:0.9rem;  
}

p {
  font-size:0.8rem;   
}

.Caption {
  font-size: 0.6rem;
}

.Content {
  background-color: $white;
  width: 100%;
  margin: 15px auto;
  padding: 10px;
  border-radius: 5px;
}

.MainContent {
  width: 100%;
  background-color:$tintColorBGColor;
  color: $contentFontColor;  
}

.MainPadding {
  padding: 8px;
}

.SpinnerBox {
  width:100%;
  min-height:280px;
  display:flex;
  align-items:center;
  justify-content:center;
}

.MenuArea {
  width: 100%;
  margin: 30px auto 0px auto;
  display: flex;
  flex-flow: column;
  color: $contentFontColor;
  justify-content:center;
  align-items: center;
  margin: 15px auto 0 auto;
}

.MenuItem{
  flex:1;
  max-width: 280px;
  min-width: 280px;
  text-align: center;
  padding: 15px;
  margin: 10px;
  background-color: $white;
  border-radius: 5px;
  border: solid 1px $primaryButtonBGColor;
}
.MenuItem:hover{
  background-color: $primaryButtonBGColorTint;   
}

.MenuItem h5{
  font-size: 0.98rem;
  padding-bottom: 10px;
}

.MenuItem p{
  font-size: 0.9rem;
  text-align: left;
}

/* width height */
body::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
 
/* Track */
body::-webkit-scrollbar-track {
  background: #aaa;
}

/* Handle */
body::-webkit-scrollbar-thumb {
  background-color: #dfdfdf;
  border-radius: 2.5px;
}

/* Handle on hover */
body::-webkit-scrollbar-thumb:hover {
  background: #eee; 
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  opacity: 0.5; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  opacity: 0.5
}

::-ms-input-placeholder { /* Microsoft Edge */
  opacity: 0.5
}



.Item {
  margin: 0 0 20px 0;
}

.PageHeader {
  width: 100%;
  margin: 0px auto;
  padding: 0px;
}

.Item ul,
.Item ol {
    list-style-position: outside;
    font-size:0.8rem;
    margin: 5px 20px;
    padding: 0px;
}

.Item ol ol {
    list-style-type: lower-alpha;
    font-size:0.78rem;
}

.Table {
  width: 95%;
  margin: 10px 20px 10px 10px; 
  font-size:0.8rem; 
  display: flex;
  flex-wrap: wrap;
}


.Table dt
{
  padding: 10px;
  background: $tintColorBGColor;
  border: 1px solid $contentFontTintColor;
  width: 100%;
  min-height:20px; 
}

.Table dd 
{
  padding: 10px;
  border: 1px solid $contentFontTintColor;
  width: 100%; 
  min-height:20px;   
}

.BorderTop{
  border-top:1px solid $contentFontTintColor;
}

.BorderBottom{
  border-bottom:1px solid $contentFontTintColor;
}

@media (max-width:319px) {
  html, body {
    overflow-x: auto;
  }
}


@media (min-width: 530px) {
  .MainPadding {
    padding: 15px;
  }
  
  .Table dt, .Table dd 
  {
    width: 50%;
    min-height: 80px;
  }
}

@media (min-width: 640px) {
  .MenuArea {
      flex-flow: row;
      flex-wrap: wrap;
      justify-content:flex-start;
      align-items: flex-start;
  }
}

@media (min-width: 700px) {
  .Content {
    width: 100%;
    padding: 20px;
  }  
}

@media (min-width: 1024px) { 
    .MenuItem{
        padding: 15px;
        max-width: 320px;
        min-width: 300px;
    }    
}

@media (min-width: 1500px) {
    .MenuItem{
        padding: 20px;
        max-width: 480px;
        min-width: 320px;
    }  
}

