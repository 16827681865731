@import "../../constants/Colors.scss";

.Footer {
    width: 100%;
    padding: 5px 5px 15px 15px;
    background-color: $tintColorBGColor;
}

.Item a {
    text-decoration: none;
    font-size: 0.75rem;
    line-height: 1.35rem;
    color: $contentFontColor; 
}

.Footer h5 {
    margin-top: 15px;
    font-size: 0.85rem;
    color: $contentFontColor; 
}

.Item a:hover 
.Item a:active
.Item a.active
{
    color: $contentFontTintColor;
}

.FooterContent {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
}

.Section img {
    margin: 10px 0 0 0;
    width: 60%;
    max-width: 150px;
}

.Section {
    flex:1;
    min-width: 150px;
    padding:0px;
    text-align: left;
}

.CopyRight {
    padding: 15px 0 0 0;
    font-size: 0.7rem; 
    color: $contentFontColor;
    text-align: center;    
}