@import '../../../constants/Colors.scss';

.Alert {
    position: fixed;
    width: 280px;
    width: 80%;
    min-width: 320px;
    max-width: 480px;
    top: -50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index:300;
    // padding: 15px;
    border-radius: 15px;
    background-color: #fff;
    transition: all 0.4s ease-out;
}

.Open {
    top: 50%;
}

.Close {
    top: -50%;
}

.ErrorTitle {
    border-radius: 15px 15px 0 0;
    background:$primaryButtonBGColor;
    padding:10px;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
    color: $white
}

@media print {
    .Alert {display:none}    
}