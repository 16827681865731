.RowDisplay { 
    display:flex; 
    flex-direction:row; 
    justify-content:flex-start; 
    align-items:center
}

.AvatorBox {
    display:flex;  
    flex-direction: column; 
    align-items:center; 
    justify-content:center;
}

.DisplayName {
    min-width:7em;
    font-size:0.5em;
    text-align:center
}