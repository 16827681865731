@import "../../constants/Colors.scss";

.MenuToggleBtn {
    position: absolute;
    left:0;
    top:200;
    width:30px;
    color: $contentFontColor;
    font-size: 0.9rem;
    font-weight: bold;
    background-color: $primaryButtonBGColor;
    border-radius: 0px 5px 5px 0;
    padding:5px 5px 10px 5px;
    display: block;
}

.DeskTopOnly {
    display: none;
}
.MobileOnly {
    display: block;
}

.LayoutContainer{
    width: 100%;
    display: flex;
    margin-top: 15px;
}

.LayoutMain {
    background-color: $white;
    width: 100%;
    min-width: 280px;
    border-radius: 5px;
    flex:3;
    margin:0 10px 15px 35px;
}

@media (min-width: 600px) {
    .LayoutMain {
        margin:0 10px 15px 10px;
    }
    .AdminArticle {
        margin-left: 0;
    }
    .MobileOnly{
        display: none;
    } 
    .DeskTopOnly {
        display: block;
    }  
}