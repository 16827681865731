@import "../../../constants/Colors.scss";

.DropDown {
}
  
.DropDownContent {
    display: none;
    position: absolute;
    background-color: $tintColorBGColor;
    box-shadow: 0px 8px 16px 0px $dropDownShadow;
    z-index: 1;
    overflow: auto;
}
  
.DropDown:hover .DropDownContent {
    display: block;
}

.DropDownItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 5px 10px;
}

.DropDownItem:hover {
    background-color: $contentFontTintColor;    
}