@import "../../constants/Colors.scss";

.Header {
    display:flex;
    min-width: 320px;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    padding: 30px 10px;
    border-bottom: 1px solid $contentFontTintColor;
}

.Brand {
    display:flex;
    justify-content: flex-start;
    margin:0px;
    padding:0px;
    width: 135px;
}

.NavigationItems {
    display:flex;
    flex-direction:row;
    margin: 0px 5px;
    padding: 0;
    list-style: none;
    justify-content: flex-end;
    align-items:center;
    height: 100%;
    border-bottom: none;
}

.NavigationItem  {
    display: block;
    color: gray;
    margin: 6px;
    font-weight: 700;
    font-size: 0.85rem;
    text-decoration: none; 
    padding: 0;
}

.NavigationItem:hover,
.NavigationItem:active,
.NavigationItem.active {
    color: #fdbe57;
} 

.Btn {
    margin: 0px 5px;
    display: inline-block;
    text-decoration: none; 
    color: gray; 
    padding: 5px 8px;
    border: 1px solid  gray;
    border-radius: 5px; 
    font-weight: 700;
    font-size: 0.8rem;
    text-align: center;
    background-color:#ffffff; 
}

.Btn:hover, 
.Btn:active,
.Btn.active 
{
    color: #ffffff; 
    border: 1px solid #fdbe57;
    background-color:#fdbe57; 
}