@import "../../../constants/Colors.scss";

.RadioButton {
    display: block;
    position: relative;
    padding-left: 27px;
    margin-bottom: 0px;
    cursor: pointer;
    font-size: 0.75rem;
    color:$contentFontTintColor;; 
    user-select: none;
    min-height: 45px;
  }
  
  /* Hide the browser's default checkbox */
  .RadioButton input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .Checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    background-color: $tintColorBGColor; 
  }
  
  /*On mouse-over, add a grey background color */
  .RadioButton:hover input ~ .Checkmark {
    background-color: $contentFontTintColor;
  }
  
  /* When the checkbox is checked, add a blue background */
  .RadioButton input:checked ~ .Checkmark {
    background-color: $greenTint;
    border: solid 1px $green;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .Checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .RadioButton input:checked ~ .Checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .RadioButton .Checkmark:after {
    left: 4px;
    top: 4px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: $green;
  }

  .Error {
      font-size: 0.7rem;
      color: $redColor;
  }

  .ErrorItem {
    border: solid 1px $redColor;   
  }