@import "../../../constants/Colors.scss";

.Avator {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $contentFontTintColor;
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.UserIcon {
    width: 65%;
    height: 65%;
    color: $contentFontTintColor;    
}

.LightBG {
    background-color: $white; 
}