@import "../../../constants/Colors.scss";

.CheckBox {
    display: block;
    position: relative;
    padding-left: 27px;
    margin-bottom: 0px;
    cursor: pointer;
    font-size: 0.75rem;
    color:$contentFontTintColor; 
    user-select: none;
    min-height: 45px;
  }
  
  /* Hide the browser's default checkbox */
  .CheckBox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .Checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;
    border-radius: 5px;
    background-color: $tintColorBGColor;
  }
  
  /*On mouse-over, add a grey background color */
  .CheckBox:hover input ~ .Checkmark {
    background-color: $contentFontTintColor;
  }
  
  /* When the checkbox is checked, add a blue background */
  .CheckBox input:checked ~ .Checkmark {
    background-color: $greenTint;
    border: solid 1px $green;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .Checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .CheckBox input:checked ~ .Checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .CheckBox .Checkmark:after {
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid $green;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }

  .Error {
      font-size: 0.7rem;
      color: $redColor;
  }

  .ErrorItem {
    border: solid 1px $redColor;   
  }