.LogoWithTypeFace {
    display:flex;
    justify-content: flex-start;
    align-items: center;
    width: 135px;
}

.LogoTypeFace {
    margin-left:8px;
    width: 100px;    
}