@import "../../../constants/Colors.scss";

.DropZone {
  border:5px dashed $contentFontTintColor;
  text-align:center;
  border-radius:5px;
  width:200px;
  height:200px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding:10px;
}

.DropZonePlaceHolder {
  font-size: 4em;
  color: $contentFontTintColor;
}

.DropZoneText .DropZoneError {
  margin: 15px;
  width:80%;
  color: $contentFontColor;
  font-weight:bold;
  font-size:0.65em
}
.DropZoneError{
  color: $redColor
}