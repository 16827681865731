@import "../../../constants/Colors.scss";

.TextLink {
    margin:0px auto;
    text-align: center;
    text-decoration: none;
    color: $primaryButtonBGColor;
}

.TextLink:hover {
    color: $primaryButtonBGColor;
}