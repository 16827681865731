@import "../../../constants/Colors.scss";

.Button {
    box-sizing: border-box;
	width: 100%;
	padding: 0.4em;
    font-size: 0.95em;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.Button:disabled {
    color: $contentFontTintColor;
    background: $tintColorBGColor;
    cursor: not-allowed;
    border: 1px solid $contentFontTintColor;
}

.Button:hover {
    opacity: 0.5; 
}

.Primary {
    background: $primaryButtonBGColor;
	border: 1px solid $primaryButtonBGColor;
    border-radius: 5px;	
    color: $primaryButtonTextColor;
}

.Secoundary {
    background: $secondaryButtonBGColor;
	border: 1px solid $secondaryButtonTextColor;
    border-radius: 5px;	
    color: $secondaryButtonTextColor;
}
