@import "../../constants/Colors.scss";

.Auth {
    position: fixed;
    width: 280px;
    width: 80%;
    min-width: 320px;
    max-width: 480px;
    top: -50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index:300;
    padding: 15px;
    border-radius: 15px;
    background-color: $white;
    transition: all 0.4s ease-out;
}

.Open {
    top: 50%;
}

.Close {
    top: -50%;
}

.Brand {
    display:flex;
    justify-content: center;
    width:100%;
    margin: 20px 0 15px 0;
}

.ErrorBox, .SuccesBox {
    width:80%;
    padding:10px;
    background-color: $attentionTint;
    border-radius: 5px;
    font-size: 0.85rem;
    color: $contentFontColor;
    margin: 0 auto;
}

.SuccesBox {
    background-color: $greenTint;
}